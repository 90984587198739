// =========
// media queries
// =========

img, embed, object {
  max-width: 100%;
  height: auto;
}

video {
	width: 100%;
	height: auto;
}


@media only screen and (max-width: 1024px) {

}
