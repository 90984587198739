// =========
// topbar
// =========
.topbar {
	position: absolute;
	width: 100%;
	height: 22vh;
	overflow: hidden;
	z-index: 100;
}

.topbar-content {
	position: relative;
	margin-top: 25px;
	.wrapper {
		display: flex;
		padding: 0 40px;
	}
}

.logo, .logo img {
	width: 185px;
}

.mainheader {min-height: 150px;}

.header-bg {
	position: absolute;
	width: 6000px;
	margin-left: -3000px;
	left: 50%;
	top: -253px;
	height: 538px;
	background: $color-ci-red;
	transform: skewY(-3.65deg);
	transform-origin: top left;
}


// =========
// headertext
// =========
.header-text-wrap {
	text-align: center;
	//padding: 200px 20px 70px 20px;
	height: 28vh;
	color: $color-ci-red;
	@include font-size(56);
	font-weight: bold;
	display: flex;
	align-items: center;
	align-content: center;

}

.header-text {
	text-align: center;
	margin: 17vh auto auto auto;
}

// =========
// introductory
// =========

.introductoryPage {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
	/*Center horizontaly ie */
	-ms-flex-pack: center;
	.gridCol {
		width: 50%;
		height: 72vh;
		background: $color-ci-red;
		overflow: hidden;
		color: #fff;
		border-right: 1px solid #fff;
		border-top: 1px solid #fff;
		text-align: center;
		text-decoration: none;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		position: relative;

		&:nth-child(even) {
			border-right: none;
		}
		&:nth-child(1),  &:nth-child(2)  {
			border-top: none;
		}


		.gridContent {
			margin: auto;
			margin-bottom: 5%;
			width: 100%;
			@include font-size(58);
			max-width: 500px;
			padding: 20px;
			font-weight: bold;
			position: relative;
			z-index: 100;
		}

		.gridContentDate {
			padding-top: 0.3em;
			@include font-size(34);
		}

		.gridContentImg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				opacity: 0.4;
			}
		}

		.gridIcon {
			transition: 200ms;

			&::before {
				text-align: center;
				content: "\00a0";
				background-repeat: no-repeat;
				background-position: center;
				display: block;
				height: 1.95em;
				margin-bottom: 0.2em;
			}

			&.iconMovie::before {background-image: url("../Img/Icons/Fontawesome/film-solid.svg");}
			&.iconHistory::before {background-image: url("../Img/Icons/Fontawesome/book.svg");}
		}

		/* hover effects for the gridcol */
		&:hover {
			background: rgba(157,24,57,0.95);

			.gridIcon {
				transform: scale(1.1);
			}
		}

	}

}

// =========
// introductory - Back Button
// =========
.introductoryBack {
	margin: 10px 10px 10px 25px;
	.introductoryBackLink {
		display: inline-block;
		background: $color-lightgrey;
		padding: 0.2em 0.8em;
		line-height: 1.6;
		font-weight: bold;
		text-decoration: none;
		border-radius: 1.5em;
		@include font-size(29);
		transition: 200ms;

		.home-icon {
			background-image: url("../Img/Icons/Fontawesome/home.svg");
			width: 50px;
			height: 50px;
			background-size: 100%;
			display: inline-block;
			vertical-align: bottom;
			margin-right: 15px;
		}

		&:hover {
			background: $color-ci-red;
			color: #fff;
			&::before {color: #fff;}
			.home-icon {background-image: url("../Img/Icons/Fontawesome/home-white.svg")}
		}

	}

	// fixed back button in video mode
	&.video {
		position: absolute;
		z-index: 1000;
	}
}

.videpPlayerWrap {
	position: relative;
	z-index: 0;
}


// =========
// mainContent
// =========
.mainContent {
	width: 100%;
	max-width: 1680px;
	margin: 0 auto;
	padding: 40px 0 20px 0;
}

.mainContent, .modular-box-content {
	@include font-size(32);
	font-family: "Calibri W02 Light";
	line-height: 1.3;

	h1 {@include font-size(56);}
	h3 {
		@include font-size(42);
		margin-bottom: 0.3em;
	}

}


// =========
// content images
// =========
.imgGallery {
	display: flex;
	flex-wrap: wrap;
	margin: 15px 0;
	justify-content: flex-start;
	.imgGalleryEl {
		width: 45%;
		margin-right: 2%;
		margin-bottom: 3%;

		figcaption {
			font-style: italic;
			margin-top: 0.3em;
			@include font-size(24);
		}
	}
}


// =========
// call to action
// =========
.cta-click-wrap {
	position: absolute;
	top: 38.5%;
	left: 30%;
	z-index: 2000;
	opacity: 0;
	animation-name: cta-click-show;
	animation-duration: 20s;
	animation-delay: 4s;

	.cta-click {
		width: 80px;
		height: 80px;
		position: absolute;
		z-index: 100;
		background-image: url("../Img/Icons/finger-hand-white.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		z-index: 100;
		top: 7px;
		left: -2px;
		transform: rotate(-20deg);
		animation: cta-click 1.5s infinite;
	}

	.cta-circle {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 5px solid #000;
		display: flex;
		position: relative;
		z-index: 0;
		opacity: 0;
		animation: cta-circle 1.5s infinite;

		&.small {
			width: 3px;
			height: 3px;
			margin: auto;
		}
	}
}

@keyframes cta-click-show {
	0% {
		opacity: 0;
	}
	5% {opacity: 1;}
	50% {opacity: 1;}
	95% {opacity: 1;}
	100% {
		opacity: 0;
	}
}

@keyframes cta-click {
	0% {
	}

	30% {
		top: 7px;
		left: -2px;

	}

	40% {
		top: 7px;
		left: -2px;
	}

	50% {
		transform: scale(0.9) rotate(-20deg);
	}

	60% {
		transform: scale(1) rotate(-20deg);
	}

	100% {
		top: 10px;
		left: 1px;
	}

}


@keyframes cta-circle {
	0% {

	}

	40% {
		opacity: 0.5;
		transform: scale(1.3);
	}

	100% {
		opacity: 0;
	}

}

// =========
// audio
// =========
.audioplayer {margin-bottom: 25px;}
.audioplayer-caption {
	@include font-size(20);
	font-style: italic;
}

audio {
	transform: scale(1.2);
	transform-origin: left bottom;
	width: 76%;
}

