// =========
// grid
// =========
.gridwrap {
	display: flex;
	justify-content: space-between;

	.gridcol {
		&.col-1-2 {flex-basis: 48%;}
		&.col-1-3 {flex-basis: 30%;}
		&.col-2-3 {flex-basis: 66%}
	}
}

