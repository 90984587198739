nav {width: 100%;}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  float: left;
  display: block;
  padding: 0 0.5em 0 0;
  margin: 0;
  a {

    // level 1
    &.nav-lv1 {
      color: #000;
      &.act, &:hover {color: #999;}
    }

  }
}
