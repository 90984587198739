// fancybox general
.fancybox-is-open .fancybox-bg {
  opacity: 0.5;
}

// fancybox modular boxes

.fancybox-slide--html {
  .fancybox-content {
    max-width: 1280px;
    max-height: 85%;
    border: 1px solid $color-ci-red;
    padding: 60px 40px 25px 30px;

    p:last-child {padding: 0;}
  }

  .fancybox-close-small {
    padding: 2px;
    top: 10px;
    right: 10px;
  }
}

.modular-box-content {
 margin-bottom: 35px;
}

