// =========
/// Colors
// =========
$color-default: #ff1414;
$color-ci-red: #9d1839;
$color-ci-red-hover: #821733;
$color-lightgrey: #f0efe8;
// =========
// fonts > font-family
// =========

// =========
// fonts > font-sizes
// =========



