///
/// TYPO3 CEs
///

///
/// TEXT & MEDIA
///

/// img inside the text (right / left)
.ce-textpic.ce-right.ce-intext .ce-gallery,
.ce-textpic.ce-left.ce-intext .ce-gallery {width: 40%;}

/// img inside the text inside 2 cols
.ce-textpic.ce-intext .ce-gallery {
  &[data-ce-columns="2"] .ce-column {
    width: 48%;
    margin-right: 4%;
    &:last-child {margin-right: 0;}
  }
}

/// img above the text inside 5 cols
.ce-textpic.ce-above .ce-gallery, .ce-textpic.ce-below .ce-gallery {
	&[data-ce-columns="2"] .ce-column {
		width: 47.5%;
		margin-right: 2.5%;
		float: left;
		&:last-child {margin-right: 0;}
	}
	&[data-ce-columns="3"] .ce-column {
		width: 31.66%;
		margin-right: 2.5%;
		float: left;
		&:last-child {margin-right: 0;}
	}
	&[data-ce-columns="4"] .ce-column {
		width: 23.125%;
		margin-right: 2.5%;
		float: left;
		&:last-child {margin-right: 0;}
	}
  &[data-ce-columns="5"] .ce-column {
    width: 18%;
    margin-right: 2.5%;
    float: left;
    &:last-child {margin-right: 0;}
  }
}

/// fix for IE bug with max-width in imgs
.ce-textpic figure.image {table-layout: fixed;width: 100%;}

/// img border
.ce-border img {
  box-sizing: border-box;
  border: 1px solid #000;
}

.ce-textpic.ce-left.ce-above, .ce-textpic.ce-left.ce-below {
  .ce-gallery, .ce-column {
    float: none;
  }
  // fix for IE bug with max-width in imgs
  figure.image {table-layout: fixed;width: 100%;}
}
// =========
// TEXT & MEDIA - Video
// =========
.ce-gallery, .ce-column.type-youtube {
  float: none;
  width: 100%;
}

// video iframe (responsive)
.ce-textpic .ce-gallery figure.video {display: block;}

.ce-textpic .video-embed {
  position: relative;
  padding-bottom: 55%;
  padding-top: 1.18%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

// =========
// LISTS
// =========
.ce-intext.ce-left ol, .ce-intext.ce-left ul {padding-left: inherit;}

// =========
// TABLE
// =========
.ce-table {
  border-collapse: collapse;
  thead th, td {border: 1px solid #eee;}
}
