@font-face{
	font-family:"Calibri W02 Regular";
	src:url("../Fonts/906939/6f0334a6-705a-43b8-95ce-0b2a4027a3f4.eot?#iefix");
	src:url("../Fonts/906939/6f0334a6-705a-43b8-95ce-0b2a4027a3f4.eot?#iefix") format("eot"),url("../Fonts/906939/eddca627-1285-4078-8527-442a93fb062e.woff2") format("woff2"),url("../Fonts/906939/67262388-98cb-4d91-a364-b35af76b9b1e.woff") format("woff"),url("../Fonts/906939/7711a542-a10e-491e-bd9d-7131e1fc3283.ttf") format("truetype");
	font-display: swap;
}
@font-face{
	font-family:"Calibri W02 Bold";
	src:url("../Fonts/906942/6e52d45c-3ec8-488c-b8a2-a148e715aeb0.eot?#iefix");
	src:url("../Fonts/906942/6e52d45c-3ec8-488c-b8a2-a148e715aeb0.eot?#iefix") format("eot"),url("../Fonts/906942/f7916c07-ab29-44f5-9e10-3846e10a48ec.woff2") format("woff2"),url("../Fonts/906942/87dc3446-f284-479a-8034-f02bdae49b84.woff") format("woff"),url("../Fonts/906942/0f9b2ecf-01f7-47f9-bba8-13b5356d3eff.ttf") format("truetype");
	font-display: swap;
}
@font-face{
	font-family:"Calibri W02 Light";
	src:url("../Fonts/1371185/43fffff2-e1f8-48d7-a7b3-1424675306ac.eot?#iefix");
	src:url("../Fonts/1371185/43fffff2-e1f8-48d7-a7b3-1424675306ac.eot?#iefix") format("eot"),url("../Fonts/1371185/0243429e-494c-4145-9cde-079c0a716390.woff2") format("woff2"),url("../Fonts/1371185/c9e4b93e-12ea-4d36-959b-ca784281b056.woff") format("woff"),url("../Fonts/1371185/d09d3848-2f3a-46f2-b136-145d45a35b0c.ttf") format("truetype");
	font-display: swap;
}

a {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

a:link, a:visited, a:hover {
	color: $color-ci-red;
	text-decoration: none;
}

h1 {
	font-family: "Calibri W02 Light";
	font-weight: normal;
	text-transform: uppercase;
	color: $color-ci-red;
	margin: 0 0 0.1em 0;
}


h3 {
	font-family: "Calibri W02 Light";
	font-weight: normal;
	margin: 0 0 0.65em 0;
	color: #9d1839;
}


figure {margin: 0;}

p.bodytext {
	padding: 0 0 1em 0;
	max-width: 1420px;
	margin: 0;
	&:last-child {padding: 0;}
}
.csc-textmedia-text, .csc-textmedia-gallery-column {
	padding: 0 0 1em 0;
}

body {
	line-height: 1.1;
	font-family: "Calibri W02 Regular",Candara,Segoe,"Segoe UI",Optima,Arial,sans-serif;
}


