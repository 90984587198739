// =========
// map
// =========

// default map configurations
#map_canvas, .osm-map {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

// google maps
#map_canvas img {max-width: none;}
.map-form {margin: 1em 0 20px 0;}
.map-label {display: block;}

#gmap-adress-select {display: none;}

#directions-panel {
  width: 100%;
  height: 100%;
  overflow: auto;
}

// open street maps
.osm-map {
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}